import * as React from 'react';
import { UserState, VehicleMessage } from '../../../types';
import { DataGridPro, GridRenderCellParams, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { API_HOST } from 'src/constants';
import { requestWrapper } from 'src/actions/Ratings';
import { Tooltip } from '@mui/material';
import { unmarshalVehicleMessage } from 'src/types/unmarshal';


export const MessagesTable = ({ search, userData }: { search: string, userData: UserState }) => {

    const [gridData, setGridData] = React.useState({
        loading: true,
        rows: [],
        rowCount: 0,
        rowsPerPageOptions: [10],
        pageSize: 10,
        page: 1
    });
    const updateData = (k: any, v: any) => setGridData((prev) => ({ ...prev, [k]: v }));

    const searchMessages = async (search: string) => {
        const messages: VehicleMessage[] = [];
        let url = `${API_HOST}/vehicle_messages.json?token=${userData.token}&page=${gridData.page}`;

        if (search !== undefined && search !== '') {
            url += `&query=${search}`;
        }

        const response = await requestWrapper(() => fetch(url));
        const json = await response.json();
        const messagesJson = json['vehicle_messages'] as object[];
        messagesJson.forEach(obj => {
            const message = unmarshalVehicleMessage(obj);
            messages.push(message);
        });

        updateData("rowCount", json['total_messages'] as number);
        updateData("rows", messages);
    };

    React.useEffect(() => {
        updateData("page", 1);
        updateData("loading", true);
        searchMessages(search).catch(error => console.log(error));
        updateData("loading", false);
    }, [search]);


    React.useEffect(() => {
        updateData("loading", true);
        searchMessages(search).catch(error => console.log(error));
        updateData("loading", false);
    }, [gridData.page]);

    const columns = [{
        field: 'id',
        headerName: 'ID',
        flex: 1,
        sortable: false
    }, {
        field: 'message',
        headerName: 'Message',
        flex: 5,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Tooltip title={params.value}>
                    <div style={{ display: 'inline-block' }}>
                        {params.value}
                    </div>
                </Tooltip>
            );
        }
    }, {
        field: 'urgent',
        headerName: 'Urgent',
        flex: 1,
        sortable: false
    }, {
        field: 'source',
        headerName: 'Source',
        flex: 2,
        sortable: false
    }, {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        sortable: false
    }, {
        field: 'sentAt',
        headerName: 'Sent At',
        flex: 2,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams) => {
            const sentTime = new Date(params.value);
            const currentYear = new Date().getFullYear();
            return sentTime.toLocaleString('en-US', {
                // only include the year if it's not the current year
                year: sentTime.getFullYear() !== currentYear ? "numeric" : undefined,
                month: "numeric",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                timeZoneName: "short"
            });
        },
    }, {
        field: 'vehicleExternalId',
        headerName: 'Truck ID',
        flex: 1,
        sortable: false,
        valueGetter: (params: GridValueGetterParams) => {
            const message: VehicleMessage = params.row;
            return message.vehicleName || message.vehicleExternalId || `${message.vehicleId} (WO)`;
        },
    }, {
        field: 'latitude',
        headerName: 'Latitude',
        flex: 1,
        sortable: false
    }, {
        field: 'longitude',
        headerName: 'Longitude',
        flex: 1,
        sortable: false
    }, {
        field: 'error',
        headerName: 'Error',
        flex: 2,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => {
            return (
                <Tooltip title={params.value}>
                    <div style={{ display: 'inline-block' }}>
                        {params.value}
                    </div>
                </Tooltip>
            );
        }
    }];

    return (
        <div>
            <DataGridPro
                components={{ NoRowsOverlay: () => <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', width: '100%' }}><div style={{ display: 'inline-block', zIndex: 99, position: 'relative', padding: '5%' }}>No messages</div></div> }}
                disableColumnMenu={true}
                columns={columns}
                pagination
                autoHeight
                disableSelectionOnClick
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        },
                    },
                }}
                paginationMode="server"
                loading={gridData.loading}
                rows={gridData.rows}
                rowCount={gridData.rowCount}
                rowsPerPageOptions={gridData.rowsPerPageOptions}
                page={gridData.page - 1}
                pageSize={gridData.pageSize}
                onPageChange={(page) => {
                    updateData("page", page + 1);
                }}
            />
        </div>
    );
};
