import { Action, ActionCreator, Dispatch } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AlertData, WeatherConditions, LocationData, RatingsState, StoreState, WeatherData, WeatherState, WeatherRunTimes } from '../types';
import { API_HOST, EVENTS_HOST } from '../constants';
import 'redux-thunk';
import { createLogoutAction } from './User';
import { action, props, union } from 'ts-action';
import { loadImpactTilesetsIfNeeded, requestWrapper } from "./Ratings";
import { RatingKey } from 'src/types/RatingKey';
import { Config } from 'src/components/shared/useConfig';
import { unmarshalAlertData } from 'src/types/unmarshal';
import moment from 'moment';

export const ReceiveWeatherData = action('RECEIVE_WEATHER_DATA', props<{
    weatherData: object;
    receivedAt: number;
}>());

export const ReceiveEventsData = action('RECEIVE_EVENTS_DATA', props<{
    eventsData: object;
    receivedAt: number;
}>());

export const LoadWeatherFail = action('LOAD_WEATHER_FAIL', props<{ error?: object }>());

export const LoadEventsFail = action('LOAD_EVENTS_FAIL', props<{ error?: object }>());

export const LoadWeatherStart = action('LOAD_WEATHER_START');

export const LoadForecastStart = action('LOAD_FORECAST_START');

export const LoadEventsStart = action('LOAD_EVENTS_START');

export const WeatherAction = union(ReceiveWeatherData, ReceiveEventsData,
    LoadWeatherFail, LoadEventsFail,
    LoadWeatherStart, LoadForecastStart, LoadEventsStart);

export function fetchWeatherData(
    token: string,
    portalToken: string,
    city: LocationData,
    desiredWeatherKey: RatingKey,
    weatherRunTimes: WeatherRunTimes | undefined,
    subhourlyWeatherRunTime: Date | undefined,
) {
    return (dispatch: Dispatch<any>) => {
        dispatch(LoadWeatherStart());

        let req: Promise<Response>;
        let url = `${API_HOST}/weather?token=${token}&weather_api_token=${portalToken}`;
        if (city.id !== undefined) {
            url += `&city_id=${city.id}`;
        } else {
            let cityName = encodeURIComponent(city.name);
            let latitude = city.latitude;
            let longitude = city.longitude;
            let zip = city.zip;
            url += `&city_name=${cityName}&latitude=${latitude}&longitude=${longitude}&zip=${zip}`;
        }

        // sync weather forecast data with ratings data runtime if available
        if (weatherRunTimes && desiredWeatherKey) {
            const runTime = weatherRunTimes[desiredWeatherKey];
            if (runTime) {
                url += `&run_time=${new Date(runTime).toISOString()}`;
            }
        }
        if (subhourlyWeatherRunTime) {
            url += `&subhourly_run_time=${subhourlyWeatherRunTime.toISOString()}`;
        }

        const returnOptions = ['subhourly'];
        if (Config.getBoolean(Config.Key.ShowGlobalAlerts)) {
            returnOptions.push('global_alerts');
        }
        if (returnOptions.length > 0) {
            url += `&return=${returnOptions.join(',')}`;
        }

        req = requestWrapper(() => fetch(url));

        return req.then(
            (response: Response) => response.json(),
            (error: Error) => console.log('Error fetching weather data.', error)
        ).then((json: JSON) => {
            if (json['error'] === 'please authenticate' || json['error'] === 'please use a token with weather permission') {
                dispatch(createLogoutAction(token));
                return undefined;
            } else if (json['error'] !== undefined) {
                // if we get a "invalid runTime, table not found" error, refresh impact tiles to get new run times
                if (json['error'] === 'invalid runTime, table not found') {
                    dispatch(loadImpactTilesetsIfNeeded(true));
                }
                console.error('there was an error fetching weather data', json);
                return Promise.reject();
            } else {
                // server might update the city info if anything was missing
                return dispatch(ReceiveWeatherData({ weatherData: json, receivedAt: Date.now() }));
            }
        });
    };
}

function shouldFetchWeatherData(weatherState: WeatherState, ratingState: RatingsState, desiredRatingKey: RatingKey, selectedCityLatitude: number, selectedCityLongitude: number): boolean {
    const timestamp = Date.now();
    let weatherStale = weatherState.weatherData === undefined || timestamp > weatherState.cacheExpiryTime;

    // if the first hour of data does not match the first hour of the tiles,
    // then we know we are using a different run time
    let differentRunTime = false;
    const firstHourOfData: WeatherConditions | undefined = weatherState.weatherData?.hourly?.[0];
    if (firstHourOfData?.time === ratingState.weatherTilesets.value?.[desiredRatingKey]?.[0].time) {
        differentRunTime = true;
    }

    let differentCity = false;
    if (weatherState.weatherData !== undefined && weatherState.weatherData.city.latitude !== selectedCityLatitude && weatherState.weatherData.city.longitude !== selectedCityLongitude) {
        differentCity = true;
    }

    console.debug(`shouldFetchWeatherData(weatherStale=${weatherStale}, differentRunTime=${differentRunTime}, differentCity=${differentCity})`);

    return weatherStale || differentRunTime || differentCity;
}

export const loadWeatherIfNeeded: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = () => {
    return (dispatch, getState) => {
        const state = getState();
        const weatherState = state.weather;
        const ratingsState = state.ratings;
        const token = state.user.token;
        const portalToken = state.user.portalToken;
        let city = state.selectedCity.selectedCity;

        if (typeof token === 'undefined' || typeof portalToken === 'undefined' || typeof city === 'undefined') {
            return Promise.resolve();
        }

        city = { ...city };

        const desiredRatingKey = state.user.currentPage === 'dashboard' ? state.dashboardView.selectedMapType.ratingKey : state.impactView.selectedMapType.ratingKey;
        if (shouldFetchWeatherData(weatherState, ratingsState, desiredRatingKey, city.latitude, city.longitude) && typeof token !== 'undefined') {
            return Promise.resolve(dispatch(fetchWeatherData(token, portalToken, city, desiredRatingKey, ratingsState.weatherTilesets.value?.runTimes, city.subhourlyImpactSummary?.sourceRunTime)).then(() => { }));
        } else {
            return Promise.resolve();
        }
    };
};

function shouldFetchSubhourlyWeatherData(weatherState: WeatherState, ratingState: RatingsState, desiredRatingKey: RatingKey, selectedCity: LocationData): boolean {
    const timestamp = Date.now();
    let weatherStale = weatherState.weatherData === undefined || timestamp > weatherState.cacheExpiryTime;

    let differentCity = false;
    if (weatherState.weatherData !== undefined && weatherState.weatherData.city.latitude !== selectedCity.latitude && weatherState.weatherData.city.longitude !== selectedCity.longitude) {
        differentCity = true;
    }

    let differentRunTime = false;
    if (selectedCity && selectedCity.subhourlyImpactSummary?.sourceRunTime?.getTime() !== weatherState.weatherData?.subhourly?.at(0)?.time.getTime()) {
        differentRunTime = true;
    }

    console.debug(`shouldFetchSubhourlyWeatherData(weatherStale=${weatherStale}, differentCity=${differentCity}), differentRuntime=${differentRunTime}`);

    return weatherStale || differentCity || differentRunTime;
}

export const loadSubhourlyWeatherIfNeeded: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = () => {
    return (dispatch, getState) => {
        const state = getState();
        const weatherState = state.weather;
        const ratingsState = state.ratings;
        const token = state.user.token;
        const portalToken = state.user.portalToken;
        let city = state.selectedCity.selectedCity;

        if (typeof token === 'undefined' || typeof portalToken === 'undefined' || typeof city === 'undefined') {
            return Promise.resolve();
        }

        city = { ...city };

        if (shouldFetchSubhourlyWeatherData(weatherState, ratingsState, state.nowcastView.selectedMapType.ratingKey, city) && typeof token !== 'undefined') {
            return Promise.resolve(dispatch(fetchWeatherData(token, portalToken, city, state.nowcastView.selectedMapType.ratingKey, ratingsState.weatherTilesets.value?.runTimes, city.subhourlyImpactSummary?.sourceRunTime)).then(() => { }));
        } else {
            return Promise.resolve();
        }
    };
};

export function fetchWeatherDataOnce(
    token: string,
    portalToken: string,
    city: LocationData,
    abortController: AbortController,
    runTime: Date | undefined,
    subhourly: boolean = false
): Promise<WeatherData> {
    let url = `${API_HOST}/weather?token=${token}&weather_api_token=${portalToken}`;

    if (city.id !== undefined) {
        url += `&city_id=${city.id}`;
    } else {
        let cityName = encodeURIComponent(city.name);
        let latitude = city.latitude;
        let longitude = city.longitude;
        let zip = city.zip;
        url += `&city_name=${cityName}&latitude=${latitude}&longitude=${longitude}&zip=${zip}`;
    }

    if (runTime) {
        url += `&run_time=${new Date(runTime).toISOString()}`;
    }

    const returnOptions = [];
    if (subhourly) {
        returnOptions.push('subhourly');
    }
    if (Config.getBoolean(Config.Key.ShowGlobalAlerts)) {
        returnOptions.push('global_alerts');
    }
    if (returnOptions.length > 0) {
        url += `&return=${returnOptions.join(',')}`;
    }

    return requestWrapper(() => fetch(url, { signal: abortController.signal })).then(
        (response: Response) => {
            let json = response.json();
            if (json) {
                return json;
            } else {
                console.log("no response json", response);
                throw new Error("no response json");
            }
        },
        (error: Error) => {
            console.log('error making ratings request.', error);
            throw error;
        }
    ).then((json?: JSON) => {
        if (!json) {
            throw new Error("json was undefined");
        }

        if (json['error'] === 'please authenticate') {
            throw new Error("not logged in");
        } else if (json['error'] === 'location outside model bounds') {
            throw new Error("location outside model bounds");
        } else {
            let hourly: WeatherConditions[] = (json['hourly'] as [object]).map(hourlyJSON => {
                let hour: WeatherConditions = {
                    time: new Date((hourlyJSON['time'] as number) * 1000),
                    temperature: hourlyJSON['temperature'] as number,
                    rainfall: hourlyJSON['rainfall_rate'] as number,
                    snowfall: hourlyJSON['snowfall_rate'] as number,
                    totalPrecipitation: hourlyJSON['total_precipitation'] as number,
                    windSpeed: hourlyJSON['wind_speed'] as number,
                    windGust: hourlyJSON['wind_gust'] as number,
                    heatIndex: hourlyJSON['heat_index'] as number,
                    visibility: hourlyJSON['visibility'] as number,
                    windGustProbability: hourlyJSON['gust_gte_45_probability'] as number,
                };

                return hour;
            });

            let subhourly: WeatherConditions[] | undefined = undefined;

            if (json['subhourly'] !== null) {
                subhourly = (json['subhourly'] as [object]).map((subhourlyJSON, i) => {
                    let hour: WeatherConditions = {
                        time: new Date((subhourlyJSON['time'] as number) * 1000),
                        temperature: subhourlyJSON['temperature'] as number,
                        totalPrecipitation: subhourlyJSON['total_precipitation'] as number,
                        rainfall: subhourlyJSON['rainfall_rate'] as number,
                        snowfall: subhourlyJSON['snowfall_rate'] as number,
                        windSpeed: subhourlyJSON['wind_speed'] as number,
                        windGust: subhourlyJSON['wind_gust'] as number,
                        heatIndex: subhourlyJSON['heat_index'] as number,
                        visibility: subhourlyJSON['visibility'] as number,
                        lightningProbability: subhourlyJSON['lightning_probability'] as number,
                        windGustProbability: subhourlyJSON['gust_gte_45_probability'] as number,
                    };

                    return hour;
                });
            }

            // fill in the windgust data from hourly for the sites timeline to use
            subhourly = subhourly?.map((wdata: WeatherConditions) => {
                const shHour = moment(wdata.time).toDate();
                shHour.setMinutes(0);
                const hourConditions = hourly.find((hour: WeatherConditions) => hour.time.getTime() === shHour.getTime());
                wdata.windGustProbability = hourConditions?.windGustProbability;
                return wdata;
            });

            let city: LocationData = {
                zip: json['city']['zip'] as string,
                id: json['city']['id'] && json['city']['id'] as number,
                name: json['city']['name'] as string,
                latitude: json['city']['latitude'] as number,
                longitude: json['city']['longitude'] as number,
                timezone: (json['time_zone'] ?? undefined) as string | undefined,
                needsGeocoding: false,
                impactSummaries: [],
            };

            // reverse order of alerts to have higher priorty alerts at top of list
            let alerts: AlertData[] = (json['alerts'] as object[]).reverse().map(unmarshalAlertData);

            return { hourly, subhourly, city, alerts };
        }
    });
}

export function fetchEventsData(token: string) {
    return (dispatch: Dispatch<any>) => {
        dispatch(LoadEventsStart());

        let req: Promise<Response>;
        const url = `${EVENTS_HOST}/current?token=${token}&event_types=all&return=storm_reports,power_outages`;
        req = requestWrapper(() => fetch(url));
        // uncomment to load a local events json from the public folder easily
        // console.debug(`loading events from local file instead of ${url}`);
        // req = requestWrapper(() => fetch('/latest_earthquakes.json'));

        return req.then(
            (response: Response) => response.json(),
            (error: Error) => console.log('Error fetching weather events data.', error)
        ).then((json: JSON) => {
            if (json['error'] === 'invalid token') {
                dispatch(createLogoutAction(token));
                return undefined;
            } else if (json['error'] === 'permission not granted') {
                console.warn('user does not have permissions for events data');
                return undefined;
            } else {
                return dispatch(ReceiveEventsData({ eventsData: json, receivedAt: Date.now() }));
            }
        });
    };
}

export const loadEventsIfNeeded: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = () => {
    return (dispatch, getState) => {
        let weatherState = getState().weather;
        let token = getState().user.portalToken;

        if (typeof token === 'undefined') {
            return Promise.resolve();
        }

        const timestamp = Date.now();
        let eventsStale = weatherState.eventsExpiryTime === undefined || timestamp > weatherState.eventsExpiryTime;

        if (eventsStale) {
            return Promise.resolve(dispatch(fetchEventsData(token)).then(() => { }));
        } else {
            return Promise.resolve();
        }
    };
};