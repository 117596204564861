import { action, props, union } from 'ts-action';
import { DashboardView } from 'src/components/Client/Dashboard/ImpactDetailView';
import { RouteData } from 'src/types/routes';
import { Action, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { StoreState, VehicleTrackingData } from 'src/types';
import { API_HOST } from 'src/constants';
import { requestWrapper } from './Ratings';
import { ReceiveRouteRefreshError, StartedRouteRefresh } from './User';
import { DashboardOverlay } from 'src/components/Client/Dashboard';
import { ImpactMapSection } from 'src/components/Client/Impact/sections';
import { MapType } from 'src/types/MapType';

export const DashboardSetSelectedMap = action('DASHBOARD_SET_SELECTED_MAP', props<{
    selectedMapCategory: ImpactMapSection;
    selectedMapType: MapType;
}>());

export const SetSelectedView = action('SET_SELECTED_DASHBOARD_VIEW', props<{ selectedView: DashboardView }>());
export const SetSelectedOverlay = action('SET_SELECTED_DASHBOARD_OVERLAY', props<{ selectedOverlay: DashboardOverlay }>());
export const SetSelectedRoute = action('SET_SELECTED_DASHBOARD_ROUTE', props<{ selectedRoute: RouteData | undefined }>());
export const SetSelectedVehicle = action('SET_SELECTED_VEHICLE', props<{ selectedVehicle: VehicleTrackingData | undefined }>());

export const DashboardSetPaused = action('DASHBOARD_SET_PAUSED', props<{ paused: boolean }>());

export const DashboardSetTileOpacity = action('DASHBOARD_SET_TILE_OPACITY', props<{ tileOpacity: number }>());


export const DashboardViewAction = union(
    DashboardSetSelectedMap,
    DashboardSetPaused, DashboardSetTileOpacity,
    SetSelectedView, SetSelectedOverlay, SetSelectedRoute, SetSelectedVehicle
);

export const refreshRoute: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (route: RouteData) => {
    return (dispatch, getState) => {
        const token = getState().user.token;

        if (token === undefined) {
            throw new Error('refreshing route without token');
        }

        dispatch(StartedRouteRefresh({ route }));

        const payload: RequestInit = {
            cache: 'no-cache',
            method: 'POST',
            mode: 'cors'
        };

        return requestWrapper(() => fetch(`${API_HOST}/routes/${route.id}/refresh?token=${token}`, payload))
            .then(
                (response: Response) => response.json(),
                (error: Error) => {
                    console.log('Error refreshing route.', error);
                    dispatch(ReceiveRouteRefreshError({ route, error: error.message }));
                }
            ).then((json: JSON) => {
                if (json['error'] !== undefined) {
                    const error = json['error'];
                    console.log('Error refreshing route, from server:', error);
                    dispatch(ReceiveRouteRefreshError({ route, error }));
                    return Promise.reject();
                }
                // when we get an updated route through ActionCable, the ReceiveUpdatedRoute action
                // will remove the route from the refreshing list
                return Promise.resolve();
            });
    };
};

export const sendMessageToVehicle: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (vehicle: VehicleTrackingData, message: string, urgent: boolean) => {
    return (dispatch, getState) => {
        const token = getState().user.token;

        if (token === undefined) {
            throw new Error('sending message to vehicle without token');
        }

        const payload: RequestInit = {
            cache: 'no-cache',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token,
                message,
                urgent,
            }),
        };

        return requestWrapper(() => fetch(`${API_HOST}/vehicles/${vehicle.id}/message`, payload))
            .then(
                (response: Response) => response.json(),
                (error: Error) => {
                    console.log('Error sending message to vehicle.', error);
                }
            ).then((json: JSON) => {
                if (json['error'] !== undefined) {
                    const error = json['error'];
                    console.log('Error sending message to vehicle, from server:', error);
                    return Promise.reject();
                }

                return Promise.resolve();
            });
    };
};
