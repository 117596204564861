// used for geocoding and tracking selected city

import {
    BaseLocationData,
    LocationData,
    StoreState
} from '../types';
import { Action, ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { API_HOST } from '../constants';
import { ReceiveUpdatedRoute, ReceiveUserCityCreated, ReceiveUserCityDeleted, ReceiveUserCityUpdated, ReceiveUserCityUpdatedError, ReceiveUserCityUpdatedSuccess } from './User';
import { loadWeatherIfNeeded } from './Weather';
import { LoadRatingsFail, loadRatingsIfNeeded, requestWrapper } from './Ratings';
import { action, props, union } from 'ts-action';
import 'moment-timezone';
import { unmarshalLocation, unmarshalRoute } from 'src/types/unmarshal';
import { trackEvent } from '../analytics';

export const SelectCity = action('SELECT_CITY', props<{ city: LocationData | undefined }>());
export const StartSaveCity = action('START_SAVE_CITY', props<{ city: LocationData }>());
export const ReceiveSaveCitySuccess = action('RECEIVE_SAVE_CITY_SUCCESS', props<{ city: LocationData }>());
export const ReceiveSaveCityError = action('RECEIVE_SAVE_CITY_ERROR', props<{ city: LocationData; error: string }>());
export const ClearSaveCityError = action('CLEAR_SAVE_CITY_ERROR', props<{ city: LocationData }>());

export const GeocodeStart = action("GEOCODE_START", props<{ abortController: AbortController }>());

export const ReceiveGeocodeData = action('RECEIVE_GEOCODE_DATA', props<{ city: BaseLocationData }>());

export const SelectedCityAction = union(
    SelectCity,
    StartSaveCity, ReceiveSaveCitySuccess, ReceiveSaveCityError, ClearSaveCityError,
    GeocodeStart, ReceiveGeocodeData
);

export const getPostData = (jsonBody: any, abortController?: AbortController) => {
    let postData: RequestInit = {
        body: JSON.stringify(jsonBody),
        cache: 'no-cache',
        method: 'POST',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        signal: abortController && abortController.signal
    };
    return postData;
};

export const geocodeCity: ActionCreator<ThunkAction<Promise<any>, StoreState, void, Action<any>>> = (city: LocationData) => {
    return (dispatch, getState) => {
        let state = getState();
        let token = state.user.token;

        if (state.ratings.abortController) {
            state.ratings.abortController.abort();
        }

        let abortController = new AbortController();
        dispatch(GeocodeStart({ abortController }));

        let postData: RequestInit = getPostData(
            {
                name: city.name,
                latitude: city.latitude,
                longitude: city.longitude,
                zip: city.zip,
                token: getState().user.token
            },
            abortController
        );

        return requestWrapper(() => fetch(`${API_HOST}/geocoding/geocode?token=${token}`, postData))
            .then(
                (response: Response) => response.json(),
                (error: Error) => console.log('Error creating city.', error)
            ).then((json: JSON) => {
                const city = unmarshalLocation(json);
                dispatch(ReceiveGeocodeData({ city }));

                // now that we have a valid zip...
                dispatch(loadWeatherIfNeeded());
                dispatch(loadRatingsIfNeeded());

                return Promise.resolve();
            }).catch((error => {
                console.log("error geocoding city", error);
                return dispatch(LoadRatingsFail({ error }));
            }));
    };
};

export const selectCityIfNeeded: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (city: LocationData) => {
    return (dispatch, getState) => {
        if (getState().selectedCity.selectedCity !== undefined) {
            return Promise.resolve();
        }

        const clonedCity = { ...city };
        dispatch(SelectCity({ city: clonedCity }));
        return Promise.resolve();
    };
};

// TODO: this source method seems brittle, should there be one set of state for tracking the current city and its saving/failure/success state
// instead of separate state for create and update?
export const createCity: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (city: LocationData, source: string = 'callout') => {
    return (dispatch, getState) => {
        const token = getState().user.token;
        if (token === undefined) {
            return Promise.resolve();
        }

        trackEvent('Location Added');

        let postData: RequestInit = {
            body: JSON.stringify({
                token,
                name: city.name,
                latitude: city.latitude,
                longitude: city.longitude,
                zip: city.zip,
                ne_lat: city.neLatitude,
                ne_lng: city.neLongitude,
                sw_lat: city.swLatitude,
                sw_lng: city.swLongitude,
            }),
            cache: 'no-cache',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        if (source === 'callout') {
            dispatch(StartSaveCity({ city }));
        }

        return requestWrapper(() => fetch(`${API_HOST}/cities.json`, postData))
            .then(
                (response: Response) => response.json(),
                (error: Error) => {
                    console.log('Error creating city.', error);
                    if (source === 'callout') {
                        dispatch(ReceiveSaveCityError({ city, error: `${error}` }));
                    } else {
                        dispatch(ReceiveUserCityUpdatedError({ errors: [error] }));
                    }
                }
            ).then((json: JSON) => {
                if (json['error'] !== undefined) {
                    const error = json['error'] as string;
                    console.log('Error creating city, from server:', error);
                    if (source === 'callout') {
                        dispatch(ReceiveSaveCityError({ city, error }));
                    } else {
                        dispatch(ReceiveUserCityUpdatedError({ errors: [new Error(error)] }));
                    }
                    return Promise.reject();
                }
                if (json['errors'] !== undefined) {
                    const errors = json['errors'];
                    console.log('Error creating city, from server:', errors);
                    if (source === 'callout') {
                        dispatch(ReceiveSaveCityError({ city, error: errors.join(', ') }));
                    } else {
                        dispatch(ReceiveUserCityUpdatedError({ errors: errors.map((e: string) => new Error(e)) }));
                    }
                    return Promise.reject();
                }

                const createdCity = unmarshalLocation(json['city']);
                if (source === 'callout') {
                    dispatch(ReceiveSaveCitySuccess({ city }));
                    dispatch(SelectCity({ city: createdCity }));
                    dispatch(ReceiveUserCityCreated({ city: createdCity }));
                } else {
                    dispatch(ReceiveUserCityUpdated({ city: createdCity }));
                    dispatch(ReceiveUserCityUpdatedSuccess({ city: createdCity }));
                }
                return Promise.resolve();
            });
    };
};

export const deleteCity: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (city: LocationData) => {
    return (dispatch, getState) => {
        const token = getState().user.token;
        if (token === undefined) {
            return Promise.resolve();
        }

        if (getState().user.cities.length <= 1) {
            // do not allow users to delete their last city
            return Promise.resolve();
        }

        let payload: RequestInit = {
            cache: 'no-cache',
            method: 'DELETE',
            mode: 'cors'
        };

        return requestWrapper(() => fetch(`${API_HOST}/cities/${city.id}.json?token=${token}`, payload))
            .then(
                (response: Response) => response.json(),
                (error: Error) => console.log('Error deleting city.', error)
            ).then((json: JSON) => {
                if (json['error'] !== undefined) {
                    const error = json['error'];
                    console.log('Error deleting city, from server:', error);
                    return Promise.reject();
                }
                dispatch(SelectCity({ city: { ...city, id: undefined } }));
                dispatch(ReceiveUserCityDeleted({ city: city }));
                return Promise.resolve();
            });
    };
};

export const updateCity: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (city: LocationData) => {
    return (dispatch, getState) => {
        const token = getState().user.token;
        if (token === undefined) {
            return Promise.resolve();
        }

        let payload: RequestInit = {
            body: JSON.stringify({
                token,
                city: {
                    name: city.name,
                    latitude: city.latitude,
                    longitude: city.longitude,
                },
            }),
            cache: 'no-cache',
            method: 'PATCH',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return requestWrapper(() => fetch(`${API_HOST}/cities/${city.id}`, payload))
            .then(
                (response: Response) => response.json(),
                (error: Error) => {
                    console.log('Error updating city.', error);
                    dispatch(ReceiveUserCityUpdatedError({ errors: [error] }));
                }
            ).then((json: JSON) => {
                if (json['error'] !== undefined) {
                    const error = json['error'];
                    console.log('Error updating city, from server:', error);
                    dispatch(ReceiveUserCityUpdatedError({ errors: [new Error(error)] }));
                    return Promise.reject();
                }
                if (json['errors'] !== undefined) {
                    const errors = json['errors'];
                    console.log('Error updating city, from server:', errors);
                    dispatch(ReceiveUserCityUpdatedError({ errors: errors.map((e: string) => new Error(e)) }));
                    return Promise.reject();
                }
                const updatedCity = unmarshalLocation(json['city']);
                dispatch(ReceiveUserCityUpdated({ city: updatedCity }));
                dispatch(ReceiveUserCityUpdatedSuccess({ city: updatedCity }));
                return Promise.resolve();
            });
    };
};

export const loadCity: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (cityId: string) => {
    return (dispatch, getState) => {
        const token = getState().user.token;
        if (token === undefined) {
            return Promise.resolve();
        }

        let payload: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return requestWrapper(() => fetch(`${API_HOST}/cities/${cityId}?token=${token}`, payload))
            .then(
                (response: Response) => response.json(),
                (error: Error) => console.log('Error loading city.', error)
            ).then((json: JSON) => {
                if (json['error'] !== undefined) {
                    const error = json['error'];
                    console.log('Error loading city, from server:', error);
                    return Promise.reject();
                }
                if (json['errors'] !== undefined) {
                    const errors = json['errors'];
                    console.log('Error loading city, from server:', errors);
                    return Promise.reject();
                }
                const loadedCity = unmarshalLocation(json['city']);
                // functionally identical to updating it
                dispatch(ReceiveUserCityUpdated({ city: loadedCity }));
                return Promise.resolve();
            });
    };
};

// TODO: probably a better file to put this in?
export const loadRoute: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (routeId: string) => {
    return (dispatch, getState) => {
        const token = getState().user.token;
        if (token === undefined) {
            return Promise.resolve();
        }

        let payload: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return requestWrapper(() => fetch(`${API_HOST}/routes/${routeId}?token=${token}`, payload))
            .then(
                (response: Response) => response.json(),
                (error: Error) => console.log('Error loading route.', error)
            ).then((json: JSON) => {
                if (json['error'] !== undefined) {
                    const error = json['error'];
                    console.log('Error loading route, from server:', error);
                    return Promise.reject();
                }
                if (json['errors'] !== undefined) {
                    const errors = json['errors'];
                    console.log('Error loading route, from server:', errors);
                    return Promise.reject();
                }
                const loadedRoute = unmarshalRoute(json['route']);
                // functionally identical to updating it
                dispatch(ReceiveUpdatedRoute({ route: loadedRoute }));
                return Promise.resolve();
            });
    };
};
