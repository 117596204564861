import { action, props, union } from 'ts-action';
import { Action, ActionCreator, Dispatch } from 'redux';
import { requestWrapper } from './Ratings';
import { API_HOST } from '../constants';
import { DriverNotification, StoreState } from '../types';
import { fetchUserCities, fetchUserRoutes } from './User';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { BatchType } from '../types/PlacesView';
import { GenericBadRequestError } from "./RoutesView";

import { ReceiveUserDriverNotificationCreated, ReceiveUserDriverNotificationDeleted, ReceiveUserDriverNotificationUpdated, ReceiveUserDriverNotificationUpdatedError, ReceiveUserDriverNotificationUpdatedSuccess } from "./User";
import { unmarshalDriverNotification } from 'src/types/unmarshal';
import { trackEvent } from 'src/analytics';

export const SetSelectedPlacesTab = action('SET_SELECTED_PLACES_TAB', props<{ selectedTab: number }>());

export const OpenedUploadDialog = action('OPENED_UPLOAD_DIALOG', props<{ batchType: BatchType }>());
export const ClosedUploadDialog = action('CLOSED_UPLOAD_DIALOG');

export const StartedBatchUpload = action('STARTED_BATCH_UPLOAD', props<{ filename: string }>());
export const BatchUploadFailed = action('BATCH_UPLOAD_FAILED', props<{ errors: string[] }>());
export const BatchUploadSucceeded = action('BATCH_UPLOAD_SUCCEEDED', props<{ batchUploadId: number }>());

export const StartedProcessingBatchUpload = action('STARTED_PROCESSING_BATCH_UPLOAD');
export const ProcessingBatchUploadFailed = action('PROCESSING_BATCH_UPLOAD_FAILED', props<{ error: string }>());
export const ProcessingBatchUploadSucceeded = action('PROCESSING_BATCH_UPLOAD_SUCCEEDED');

export const PlacesViewAction = union(
    SetSelectedPlacesTab,
    OpenedUploadDialog, ClosedUploadDialog,
    StartedBatchUpload, BatchUploadFailed, BatchUploadSucceeded,
    StartedProcessingBatchUpload, ProcessingBatchUploadFailed, ProcessingBatchUploadSucceeded
);

export function initiateBatchFileUpload(file: Blob, filename: string) {
    return async (dispatch: Dispatch<any>, getState: () => StoreState) => {
        const token = getState().user.token;
        if (token === undefined) {
            throw new Error("initiating batch upload without token");
        }

        const batchType = getState().placesView.uploadBatchType;
        dispatch(StartedBatchUpload({ filename }));
        trackEvent("Batch Upload Started", { batchType });

        const formData = new FormData();
        formData.append('token', token);
        formData.append('batch_type', batchType.toString());
        formData.append('file', file, filename);

        let postData: RequestInit = {
            body: formData,
            method: 'POST',
            mode: 'cors',
        };

        return requestWrapper(() => fetch(`${API_HOST}/batch_uploads.json`, postData))
            .then((response: Response) => response.json().then((json) => { return { response, json }; }))
            .then(
                ({ response, json }) => {
                    if (response.status === 200) {
                        const batchUpload = json['batch_upload'] as object;
                        if (batchUpload === undefined) {
                            throw new Error("did not get a batch upload in success response from create batch upload route");
                        }

                        const batchUploadId = batchUpload['id'] as number;
                        if (batchUploadId === undefined) {
                            throw new Error("got a batch upload without an ID in create batch upload route");
                        }

                        // receive user data
                        return dispatch(BatchUploadSucceeded({ batchUploadId }));
                    } else {
                        let errors: string[];
                        if (json['errors'] && Array.isArray(json['errors'])) {
                            errors = json['errors'];
                        } else {
                            errors = ['Invalid response from server. Please try again later'];
                        }
                        trackEvent("Batch Upload Error", { batchType, step: 'validate_file', error: errors.join("; ") });
                        trackEvent("Batch Upload Ended", { batchType, result: 'error' });
                        return dispatch(BatchUploadFailed({ errors }));
                    }
                },
            ).catch((error) => {
                console.error('batch upload error:', error);
                const message = "Unknown request error. Please check your internet connection and try again later";
                trackEvent("Batch Upload Error", { batchType, step: 'upload_file', error: message });
                trackEvent("Batch Upload Ended", { batchType, result: 'error' });
                return dispatch(BatchUploadFailed({ errors: [message] }));
            });
    };
}

export function processBatchUpload(batchUploadId: number) {
    return async (dispatch: ThunkDispatch<StoreState, void, Action<any>>, getState: () => StoreState) => {
        const token = getState().user.token;
        if (token === undefined) {
            throw new Error("processing batch upload without token");
        }

        const batchType = getState().placesView.uploadBatchType;
        dispatch(StartedProcessingBatchUpload());

        let config: RequestInit = {
            cache: 'no-cache',
            method: 'POST',
            mode: 'cors',
        };

        return requestWrapper(() => fetch(`${API_HOST}/batch_uploads/${batchUploadId}/process_batch.json?token=${token}`, config))
            .then((response: Response) => response.json().then((json) => { return { response, json }; }))
            .then(
                ({ response, json }) => {
                    if (response.status === 200) {
                        return Promise.resolve();
                    } else {
                        let error: Error;
                        if (json['error']) {
                            error = new GenericBadRequestError(json['error']);
                        } else {
                            error = new Error('Invalid response from server. Please try again later');
                        }
                        throw error;
                    }
                },
            )
            .then(() => {
                // reload data
                trackEvent("Batch Upload Completed", { batchType });
                trackEvent("Batch Upload Ended", { batchType, result: 'success' });
                if (batchType === BatchType.Location) {
                    return dispatch(fetchUserCities(token))
                        .then(() => dispatch(ProcessingBatchUploadSucceeded()));
                } else {
                    return dispatch(fetchUserRoutes(token))
                        .then(() => dispatch(ProcessingBatchUploadSucceeded()));
                }
            })
            .catch((error: Error) => {
                trackEvent("Batch Upload Error", { batchType, step: 'process_batch', error: error.message });
                trackEvent("Batch Upload Ended", { batchType, result: 'error' });
                return dispatch(ProcessingBatchUploadFailed({ error: error.message }));
            });
    };
}


export const createDriverNotification: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (driverNotification: DriverNotification) => {
    return (dispatch, getState) => {
        const token = getState().user.token;
        if (token === undefined) {
            return Promise.resolve();
        }

        let postData: RequestInit = {
            body: JSON.stringify({
                token,
                driver_notification: {
                    name: driverNotification.name,
                    notification_type: driverNotification.notificationType,
                    status: driverNotification.status,
                    frequency: driverNotification.frequency,
                    interval: driverNotification.interval,
                    window_size: driverNotification.windowSize,
                    thresholds: driverNotification.thresholds,
                    alerts: driverNotification.alerts?.toString(),
                    template: driverNotification.template,
                    urgent: driverNotification.urgent
                },
            }),
            cache: 'no-cache',
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return requestWrapper(() => fetch(`${API_HOST}/driver_notifications.json`, postData))
            .then(
                (response: Response) => response.json(),
                (error: Error) => {
                    console.log('Error creating driver notification.', error);
                }
            ).then((json: JSON) => {
                if (json['error'] !== undefined) {
                    const error = json['error'] as string;
                    console.log('Error creating driver notification, from server:', error);
                    return Promise.reject();
                }

                const createdDriverNotification = unmarshalDriverNotification(json['driver_notification']);
                dispatch(ReceiveUserDriverNotificationCreated({ driverNotification: createdDriverNotification }));

                return Promise.resolve();
            });
    };
};

export const deleteDriverNotification: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (driverNotification: DriverNotification) => {
    return (dispatch, getState) => {
        const token = getState().user.token;
        if (token === undefined) {
            return Promise.resolve();
        }

        if (getState().user.cities.length <= 1) {
            // do not allow users to delete their last city
            return Promise.resolve();
        }

        let payload: RequestInit = {
            cache: 'no-cache',
            method: 'DELETE',
            mode: 'cors'
        };

        return requestWrapper(() => fetch(`${API_HOST}/driver_notifications/${driverNotification.id}.json?token=${token}`, payload))
            .then(
                (response: Response) => response.json(),
                (error: Error) => console.log('Error deleting driver notification.', error)
            ).then((json: JSON) => {
                if (json['error'] !== undefined) {
                    const error = json['error'];
                    console.log('Error deleting driver notification, from server:', error);
                    return Promise.reject();
                }
                dispatch(ReceiveUserDriverNotificationDeleted({ driverNotification: driverNotification }));
                return Promise.resolve();
            });
    };
};

export const updateDriverNotification: ActionCreator<ThunkAction<Promise<void>, StoreState, void, Action<any>>> = (driverNotification: DriverNotification) => {
    return (dispatch, getState) => {
        const token = getState().user.token;
        if (token === undefined) {
            return Promise.resolve();
        }

        let payload: RequestInit = {
            body: JSON.stringify({
                token,
                driver_notification: {
                    name: driverNotification.name,
                    status: driverNotification.status,
                    notification_type: driverNotification.notificationType,
                    frequency: driverNotification.frequency,
                    interval: driverNotification.interval,
                    window_size: driverNotification.windowSize,
                    thresholds: driverNotification.thresholds,
                    alerts: driverNotification.alerts?.toString(),
                    template: driverNotification.template,
                    urgent: driverNotification.urgent,
                },
            }),
            cache: 'no-cache',
            method: 'PATCH',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        return requestWrapper(() => fetch(`${API_HOST}/driver_notifications/${driverNotification.id}`, payload))
            .then(
                (response: Response) => response.json(),
                (error: Error) => console.log('Error updating driver notification.', error)
            ).then((json: JSON) => {
                if (json['error'] !== undefined) {
                    const error = json['error'];
                    console.log('Error updating driver notification, from server:', error);
                    dispatch(ReceiveUserDriverNotificationUpdatedError({ errors: [new Error(error)] }));
                    return Promise.reject();
                }
                if (json['errors'] !== undefined) {
                    const errors = json['errors'];
                    console.log('Error updating driver notificaiton, from server:', errors);
                    dispatch(ReceiveUserDriverNotificationUpdatedError({ errors: errors.map((e: string) => new Error(e)) }));
                    return Promise.reject();
                }
                const updatedDriverNotification = unmarshalDriverNotification(json['driver_notification']);
                dispatch(ReceiveUserDriverNotificationUpdated({ driverNotification: updatedDriverNotification }));
                dispatch(ReceiveUserDriverNotificationUpdatedSuccess({ driverNotification: updatedDriverNotification }));
                return Promise.resolve();
            });
    };
};